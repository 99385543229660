@import "../../styles/base/variables";
@import "../../styles/base/mixins";

.sidebar {
  $this: &;

  width: $page-sidebar-width;
  height: calc(var(--vh, 1vh) * 100 - #{$page-header-height});
  // background: $color-black;
  background: var(--color-white);
  position: relative;
  transition: width 0.25s;
  overflow: auto;
  // border-right: 1px solid rgb(241, 243, 245);
  box-shadow: 1px 0px 6px 1px rgba(0, 0, 0, 0.15);
  z-index: 8;
  // border-right: 1px solid var(--color-gray-medium);

  @include for-tablet-landscape-down() {
    display: none;
  }

  &.is-collapsed {
    width: $page-sidebar-collapsed-width;
  }

  &__toggle {
    width: 20px;
    height: 20px;
    font-size: 20px;
    opacity: 0.8;
    color: var(--color-blue-darker);
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 2;
    transform: translateX(0) rotate(180deg);

    #{$this}.is-collapsed & {
      right: 50%;
      transform: translateX(50%) rotate(0);
    }
  }

  &__nav {
    $nav: &;

    @include reset-list();
    color: var(--color-white);

    &-wrapper {
      margin-top: 75px;

      @include for-tablet-landscape-down() {
        margin-top: 1em;
      }
    }

    &-item {
      &-link {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 10px 40px;
        // color: var(--color-white);
        color: var(--color-black);
        text-decoration: none;
        text-transform: uppercase;
        transition: all 0.25s;
        border-right: 3px solid rgba(0, 0, 0, 0);
        cursor: pointer;

        &:hover {
          // background-color: $color-gray-dark;
          background-color: var(--color-gray-background);
        }

        &.is-active {
          // background-color: $color-gray-dark;
          background-color: var(--color-gray-background);
          border-right: 3px solid $color-secondary;
        }

        #{$this}.is-collapsed & {
          padding: 10px 0;
          justify-content: center;
        }
      }
    }

    &-title {
      font-size: 14px;
      opacity: 1;
      transition: transform 0.25s;

      #{$nav}-item-link:hover & {
        transform: translateX(10px);
      }

      #{$this}.is-collapsed & {
        opacity: 0;
        display: none;
      }
    }

    &-icon {
      margin-right: 15px;

      #{$this}.is-collapsed & {
        margin-right: 0;
      }
    }
  }
}
