// @import "~@fullcalendar/core/main.css";
// @import "~@fullcalendar/daygrid/main.css";
// @import "~@fullcalendar/timegrid/main.css";
// @import "~@fullcalendar/timeline/main.css";
// @import "~@fullcalendar/resource-timeline/main.css";
@tailwind base;
@import "./base/_tailwind_base_adjusted.scss";
@tailwind components;
@tailwind utilities;

@import "./vendor/normalize";
@import "./base/fonts";
@import "./base/variables";
@import "./base/scaffolding";

@import "./components/form";
@import "./components/status";
@import "./components/text";
@import "./components/dropzone";
