.status {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 10px;

  &--small {
    height: 10px;
    width: 10px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }
}
