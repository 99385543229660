@import "../../styles/base/variables";
@import "../../styles/base/mixins";

.link-dropdown {
  $this: &;

  &__title {
    display: flex;
    align-items: center;
    cursor: pointer;

    #{$this}--open & svg {
      transform: rotate(180deg);
    }

    svg {
      margin-left: 5px;
      transition: transform 0.25s;
    }
  }
}
