@import "../../styles/base/variables";
@import "styles/base/mixins";

* {
  box-sizing: border-box;
}

:focus-visible{
  outline: inherit;
}

html,
body {
  font-family: "Source Sans Pro";
  font-size: 15px;
}

body {
  padding-top: $page-header-height;
}

.page {
  flex: 1;
  height: calc((var(--vh, 1vh) * 100) - var(--page-header-height));
  width: 100%;
  overflow: hidden;

  &__container {
    width: 100%;
    overflow: auto;
    height: calc(
      (var(--vh, 1vh) * 100) - var(--page-header-height) - var(--page-header-height) +
        2px
    );
    background: $color-gray-light;
  }

  &__wrapper {
    width: 100%;
    margin: 0 auto;
  }
}

.body-w-footer-height{
  height: calc((var(--vh,1vh) * 100) - var(--page-header-height) - var(--page-header-height) - var(--page-bottom-navigation-height));

  // @media (max-height: #{$bp-sm}){
  //   height: calc(var(--vh,1vh) * 100 - var(--page-header-height-2-line) - var(--page-header-height-2-line) - var(--page-bottom-navigation-height));
  // }
}

.multiline-ellipsis,
.multiline-ellipsis-2{
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;

  &-2{
    -webkit-line-clamp: 2;
  }
}

.flex {
  display: flex;
}

.flex-end {
  justify-content: flex-end;
}

a {
  color: $color-secondary;
}

fieldset > legend{
  margin-left: -0.75em;
  font-weight: 600;
  font-style: italic;
}

.bg-gradient-black {
  background: $gradient-black;
}
.bg-banner-black {
  background: var(--color-gray-darkest);
  color: var(--color-gray-lightest);
  box-shadow: var(--shadow-inner);
}

.bg-gradient-gray-vert {
  background: $gradient-gray-vert
}

.flex-spacer {
  flex: 1;
}

.slide-up-enter {
  transform: translateY(20px);
  opacity: 0;
}

.slide-up-enter.slide-up-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 200ms ease-in, transform 200ms ease-in;
}

.slide-up-exit {
  transform: translateY(0);
  opacity: 1;
}

.slide-up-exit.slide-up-exit-active {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 200ms ease-out, transform 200ms ease-out;
}
.fade-enter {
  opacity: 0;
  z-index: 1;
  display: none !important;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-out;
  display: none !important;
}

.slide-right-enter {
  transform: translateX(-20px);
  opacity: 0;
}

.slide-right-enter.slide-right-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 200ms ease-in, transform 200ms ease-in;
}

.slide-right-exit {
  transform: translateX(0);
  opacity: 1;
}

.slide-right-exit.slide-right-exit-active {
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 200ms ease-out, transform 200ms ease-out;
}

.slide-right-out-enter {
  transform: translateX(-20px);
  opacity: 0;
}

.slide-right-out-enter.slide-right-out-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 200ms ease-in, transform 200ms ease-in;
}

.slide-right-out-exit {
  transform: translateX(0);
  opacity: 1;
}

.slide-right-out-exit.slide-right-out-exit-active {
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 200ms ease-out, transform 200ms ease-out;
}

.tab-slide-enter {
  transform: translateX(20px);
  opacity: 0;
}

.tab-slide-enter.tab-slide-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 200ms ease-in, transform 200ms ease-in;
}

.tab-slide-exit {
  transform: translateX(0);
  opacity: 1;
}

.tab-slide-exit.tab-slide-exit-active {
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 200ms ease-out, transform 200ms ease-out;
}

.background {
  background: rgba(0, 0, 0, 0.75);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  z-index: $site-header-z-index + 1;
}

.flex-full {
  flex: 1;
}

.header-icon{
  width: 2rem;
  height: auto;
  display: inline-block;
  vertical-align: baseline;
  margin-right: 0.5rem;
}
.item-icon{
  width: 1.25rem;
  height: auto;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.25rem;
}
.nav-icon{
  height: 20px;
  width: 20px;
  font-size: 20px;
  opacity: 0.8;
  color: var(--color-gray-darker);
}

.delete-button{
  background-color: var(--color-gray-lighter);
  color: var(--color-red);
  border: 1px solid var(--color-gray);
  border-radius: var(--border-radius-full);
  padding: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &.slim{
    padding: 0.125rem;
  }

  .icon{
    height: 1.25rem;
    width: 1.25rem;
    color: var(--color-red);
  }
}

.icon-circle-button{
  padding: 0.5rem;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: var(--color-gray-dark);
  background-color: white;
  cursor: pointer;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.35);

  &.Mui-disabled,
  &.disabled{
    color: var(--color-gray-medium);
  }

  &.secondary{
    color: var(--color-secondary);

    &.Mui-disabled,
    &.disabled{
      color: var(--color-secondary-faded);
    }
  }
}

.icon-circle-button:hover{
  color: black;
  background-color: var(--color-gray-background);
}

@include for-tablet-portrait-down() {
  .icon-circle-button{
    padding: 0.25rem;
    width: 25px;
    height: 25px;
  }
}

.error-banner{
  background-color: var(--color-red-fade);
  border: 1px solid var(--color-red);
  padding: 0.75rem 1rem;
}

.warning-banner{
  background-color: var(--color-yellow-fade);
  border: 1px solid var(--color-yellow-bright);
  padding: 0.75rem 1rem;
}

.tab-menu-primary{
  background: var(--gradient-gray-vert);
  border-bottom: 1px solid var(--color-gray-medium);
}

.tab-menu-secondary{
  background: var(--gradient-black);
  color: var(--color-white);
}

.fc-task-complete {
  .fc-event-title {
    text-decoration: line-through !important;
  }
}

.fc-task-duration {
  padding-left: 1rem;
  position: relative;

  &:before {
    content: " ";
    width: 10px;
    height: 10px;
    background: white;
    left: 0;
    top: 4px;
    border-radius: 50%;
    position: absolute;
  }
}

.fc-unthemed td.fc-today {
  background: $color-secondary-faded;
}

.fc-theme-standard .fc-scrollgrid {
  border: none;
}

.fc-scrollgrid-section-footer {
  display: none;
}

.fc .fc-scrollgrid {
  border-left-width: 0 !important;
  border-top-width: 0 !important;
}

.fc-day-today {
  background-color: var(--color-secondary-faded) !important;
}

.fc-toolbar {
  display: none !important;
  // padding: 40px 20px 0 20px;

  .fc-today-button {
    margin-left: 0;
  }

  .fc-button {
    background: $color-gray-dark;
    border: none;
  }

  &.fc-header-toolbar {
    margin-bottom: 0;

    .fc-left {
      display: flex;
      align-items: center;
    }
  }

  h2 {
    color: rgba(0, 0, 0, 0.6);
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 0;
    margin-right: 20px;
  }
}

.fc-widget-header {
  border: none;

  th {
    border: none;
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6);
    padding: 10px 0;
    text-transform: uppercase;
  }

  .calendar__toolbar-week-view {
    display: flex;
    justify-content: center;

    .calendar__toolbar-today-badge {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .weekday {
      text-transform: uppercase;
    }

    .day {
      font-size: 24px;
      font-weight: normal;
    }
  }

  .fc-today .calendar__toolbar-today-badge {
    background: #33b679;
    color: white;
  }
}

.fc-view-container {
  background: white;
}

.fc-widget-content {
  border-color: $color-gray;

  &.fc-disabled-day {
    background: $color-gray-lighter;
  }

  &.fc-today {
    background: white;
  }

  .fc-row {
    min-height: 3em;
  }

  .fc-bgevent {
    opacity: 0.5;
  }

  .fc-day-top {
    .fc-day-number {
      float: none;
      margin: 0 auto;
      display: flex;
      width: 20px;
      height: 20px;
      padding: 0;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }

    &.fc-today .fc-day-number {
      background: #33b679;
      color: white;
    }
  }
}

.fc-timeGridWeek-view {
  & .fc-day.fc-today {
    background: white;
  }
}

.fc-timeGridDay-view {
  & .fc-day.fc-today {
    background: white;
  }

  .calendar__toolbar-week-view {
    justify-content: flex-start;
  }
}

.fc-event {
  cursor: pointer;
}

.fc-timeline-event {
  border-radius: 3px !important;
}

.fc-time-grid .fc-slats .fc-minor td {
  border-top: 0;
}

.fc-axis {
  border-top: 0;
}

.fc-resource-area {
  border-top: 1px solid #{$color-gray-middle};
  border-bottom: 1px solid #{$color-gray-middle};
  border-right: 1px solid #{$color-gray-middle};
  border-left: none;
  // border-width: 0 !important;
}

.fc-widget-header {
  border-width: 0 !important;
}
// .fc-ltr {
//   height: 100%;
// }

// .fc-view-container {
//   height: 100%;
// }

// .fc-timeline {
//   height: 100%;

//   & > table {
//     height: 100%;
//   }

//   & .fc-body {
//     height: 100%;
//   }

//   & .fc-scroller-clip {
//     height: 100%;
//   }

//   & .fc-scroller {
//     height: 100% !important;
//   }
// }

// .fc-event:hover {
//   border-color: pink;
//   .fc-content {
//     color: pink;
//   }
// }

// .MuiAutocomplete-input {
//   padding: 7px 5px 8px 5px !important;
// }

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]
  .MuiAutocomplete-input {
  padding: 4.5px !important;
}

.mention-suggestions-popover {
  border: 1px solid #eee;
  margin-top: 0.4em;
  position: absolute;
  min-width: 220px;
  max-width: 440px;
  background: pink;
  border-radius: 2px;
  box-shadow: 0px 4px 30px 0px rgba(220, 220, 220, 1);
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transform: scale(0);
}

.DateRangePicker {
  width: 100%;
}

.DateRangePicker_picker__portal {
  z-index: 10001 !important;
}

.DateRangePickerInput {
  width: 100%;
  display: flex !important;
  align-items: center;
  border-radius: 4px !important;
  overflow: hidden;
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.DateInput {
  flex: 1;
}

.DateInput_input {
  font-size: 1rem !important;
  font-weight: 400 !important;
  padding-top: 10.5px !important;
  padding-bottom: 10.5px !important;
  padding: 10.5px !important;
  line-height: 18px !important;
  text-align: center;
}

.control-pane-wrapper{
  .e-image-editor .e-toolbar .e-toolbar-items .e-btn-icon.e-dropdownbtn-preview {
    border: 1px solid;
  }
}

.comment_job_emphasis{
  background: var(--color-blue-lightest);
  color: var(--color-blue-dark);
}
.comment_mention_emphasis{
  background: var(--color-gray);
  color: var(--color-gray-darkest);
}

span.mention{
  color: var(--color-blue);
  font-weight: bold;
}

.editorClassName {
  border: 1px solid var(--color-gray);
  min-height: 100px;
  border-radius: 3px;
  padding: 0 1rem;
}
