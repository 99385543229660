/*
    Colors:
*/

$base_url: "/sites/default/react";

/* Brand */
$color-purple: #8b5cf6;
$color-purple-alt: #6849ad;
$color-orange: #f06332;
$color-orange-bright: #ff9900;
$color-yellow: #f4ae28;
$color-yellow-bright: #f8e71c;
$color-teal: #40ad8d;
$color-teal-light: #bde1e1;
$color-teal-dark: #11998e;

// $color-blue: #32bff0;
$color-blue: #4285f4;
$color-blue-darker: #1b4687;
$color-blue-dark: #265db5;
$color-blue-light: #5786cc;
$color-blue-lighter: #96b9ea;
$color-blue-lightest: #c2dbfc;
$color-blue-dark-fade: #4d5c73;
$color-blue-light-fade: #f4f5f6;
$color-blue-alt: #03bafc;
$color-blue-alt2: #00e1ff;

/* Layout */
$color-purple-dark: #28252f;
$color-purple-light: #857099;

$color-white: #fafcff; // This helps maintain a full hexa value for google
$color-black: #0b1019;
$color-gray-darkest: #1d232b;
$color-gray-darker: #313a47;
$color-gray-dark: #4b4f56;
$color-gray-medium: #9ea0a3;
$color-gray-middle: #d2d4d8;
$color-gray: #d3d6d9;
$color-gray-light: #f2f3f4;
$color-gray-lighter: #f7f8f9;
$color-gray-lightest: #fcfdff;
$color-gray-medium-alt: #b9babb;

$color-gray-alpha-lowest: rgba(36, 40, 45, 0.04);
$color-gray-alpha-lower: rgba(36, 40, 45, 0.12);
$color-gray-alpha-low: rgba(36, 40, 45, 0.26);
$color-gray-alpha-mid: rgba(36, 40, 45, 0.54);

$color-gray-dark-alpha: rgba(25, 28, 33, 0.15);
$color-gray-darker-alpha: rgba(27, 31, 35, 0.15);

$color-blue-lightest-alpha: #{$color-blue-lightest}55;

/* Other Colors */

/* Status */
$color-green: #35bb52;
$color-green-alt: #1c7e18;
$color-red: #f82845;
$color-red-fade: #ffc4cc;
$color-yellow-fade: #fbf6b9;

$color-primary: $color-black;
$color-primary-faded: $color-gray-middle;
$color-primary-inverse: $color-gray-lighter;
$color-secondary: $color-blue-dark;
$color-secondary-faded: $color-blue-lightest;
$color-secondary-inverse: $color-gray-darker;
$color-tertiary: $color-teal;
$color-tertiary-faded: $color-teal-light;
$color-tertiary-inverse: $color-gray-darker;

$color-mui-red: #d32f2f;

$color-rg-0: #f82845;
$color-rg-10: #f5383f;
$color-rg-20: #f34839;
$color-rg-30: #f06332;
$color-rg-40: #f17c2e;
$color-rg-50: #f3952b;
$color-rg-60: #f4ae28;
$color-rg-70: #ccd326;
$color-rg-80: #a4f823;
$color-rg-90: #91E522;
$color-rg-100: #7ed321;

$gradient-purple: linear-gradient(83.82deg, #3c2367 0%, #3c2367 100%);
$gradient-blue: linear-gradient(83.82deg, $color-blue-darker 0%, $color-blue-darker 100%);

$gradient-black: linear-gradient(90deg, $color-black 0%, $color-gray-dark 100%) center center / 100vw 100vh fixed;
$gradient-gray: linear-gradient(90deg, var(--color-gray), var(--color-gray-light) 70%);
$gradient-gray-vert: linear-gradient(var(--color-gray) 0%, var(--color-gray-light) 100%);

$gradient-blue-circle: radial-gradient($color-blue 99%, #{$color-blue}00 100%);

$icon-radial: radial-gradient(at 65% 30%, $color-gray-dark 0%, $color-black 65%);

$drop-shadow: 0px 2px 10px 0 $color-black;
$drop-shadow-sharp: 0px 1px 2px 0 $color-black;
$shadow-inner: inset 0px 2px 10px 0 rgb(0, 0, 0);
$shadow-inner-top: inset 0px 11px 9px -13px rgb(0, 0, 0);

$border-radius: 3px;
$border-radius-medium: 0.375rem;
$border-radius-large: 0.5rem;
$border-radius-full: 9999px;

$page-header-height: 62px;
$page-header-height-2-line: 86px;

$page-hub-client-selector-height: 45px;
$page-hub-footer-height: calc(54.5px + 0.5rem);

$form-stepper-height: 72px;

$page-sidebar-width: 200px;
$page-sidebar-collapsed-width: 60px;

$page-body-min-height: 450px;

$table-pagination-height: 52px;

$site-header-z-index: 6;

$filter-menu-height-desktop: 100px;

$color-audit: $color-red;
$color-accepted: $color-green;

$timing-quartic: cubic-bezier(0.9, 0.03, 0.69, 0.22);

$bp-xxs: 0px;
$bp-xs: 479px;
$bp-sm: 767px;
$bp-md: 991px;
$bp-lg: 1199px;
$bp-xl: 1799px;
$bp-xxl: 1919px;

:root {
  /* Brand */
  --color-purple: #{$color-purple};
  --color-orange: #{$color-orange-bright};
  --color-orange-bright: #{$color-orange};
  --color-yellow: #{$color-yellow};
  --color-yellow-bright: #{$color-yellow-bright};
  --color-teal: #{$color-teal};
  --color-teal-light: #{$color-teal-light};
  --color-teal-dark: #{$color-teal-dark};

  // --color-blue: #32bff0;
  --color-blue: #{$color-blue};
  --color-blue-darker: #{$color-blue-darker};
  --color-blue-dark: #{$color-blue-dark};
  --color-blue-light: #{$color-blue-light};
  --color-blue-lighter: #{$color-blue-lighter};
  --color-blue-lightest: #{$color-blue-lightest};
  --color-blue-dark-fade: #{$color-blue-dark-fade};
  --color-blue-light-fade: #{$color-blue-light-fade};
  --color-blue-alt: #{$color-blue-alt};
  --color-blue-alt2: #{$color-blue-alt2};
  // --color-ocean: #486295;
  // --color-cyan: #4fb3e5;

  /* Layout */
  --color-purple-dark: #3c2367;
  --color-purple-light: #857099;
  --color-blue-light: #{$color-blue-light};
  --color-violet-light: #f3f2ff;
  --color-white: #{$color-white};
  --color-black: #{$color-black};
  --color-gray-darkest: #{$color-gray-darkest};
  --color-gray-darker: #{$color-gray-darker};
  --color-gray-dark: #{$color-gray-dark};
  --color-gray:  #{$color-gray};
  --color-gray-medium: #{$color-gray-medium};
  --color-gray-middle: #{$color-gray-middle};
  --color-gray-light: #{$color-gray-light};
  --color-gray-lighter: #{$color-gray-lighter};
  --color-gray-lightest: #{$color-gray-lightest};
  --color-gray-background: #{$color-gray-light};
  --color-gray-medium-alt: #{$color-gray-medium-alt};

  --color-gray-alpha-lowest: #{$color-gray-alpha-lowest};
  --color-gray-alpha-lower: #{$color-gray-alpha-lower};
  --color-gray-alpha-low: #{$color-gray-alpha-low};
  --color-gray-alpha-mid: #{$color-gray-alpha-mid};
  --color-gray-dark-alpha: #{$color-gray-dark-alpha};
  --color-gray-darker-alpha: #{$color-gray-darker-alpha};
  --color-blue-lightest-alpha: #{$color-blue-lightest-alpha};

  /* Other Colors */

  /* Status */
  --color-green: #{$color-green};
  --color-green-alt: #{$color-green-alt};
  --color-red: #{$color-red};
  --color-red-fade: #{$color-red-fade};
  --color-yellow-bright: #{$color-yellow-bright};
  --color-yellow-fade: #{$color-yellow-fade};

  --color-primary: #{$color-primary};
  --color-primary-faded: #{$color-primary-faded};
  --color-primary-inverse: #{$color-primary-inverse};
  --color-secondary: #{$color-secondary};
  --color-secondary-faded: #{$color-secondary-faded};
  --color-secondary-inverse: #{$color-secondary-inverse};
  --color-tertiary: #{$color-tertiary};
  --color-tertiary-faded: #{$color-tertiary-faded};
  --color-tertiary-inverse: #{$color-tertiary-inverse};

  --color-rg-0: #{$color-rg-0};
  --color-rg-10: #{$color-rg-10};
  --color-rg-20: #{$color-rg-20};
  --color-rg-30: #{$color-rg-30};
  --color-rg-40: #{$color-rg-40};
  --color-rg-50: #{$color-rg-50};
  --color-rg-60: #{$color-rg-60};
  --color-rg-70: #{$color-rg-70};
  --color-rg-80: #{$color-rg-80};
  --color-rg-90: #{$color-rg-90};
  --color-rg-100: #{$color-rg-100};

  --gradient-purple: #{$gradient-purple};

  --gradient-black: #{$gradient-black};
  --gradient-gray: #{$gradient-gray};
  --gradient-gray-vert: #{$gradient-gray-vert};
  --gradient-blue-circle: #{$gradient-blue-circle};
  --icon-radial: #{$icon-radial};
  --drop-shadow: #{$drop-shadow};
  --drop-shadow-sharp: #{$drop-shadow-sharp};
  --shadow-inner: #{$shadow-inner};
  --shadow-inner-top: #{$shadow-inner-top};

  --color-mui-red: #{$color-mui-red};

  --border-radius: #{border-radius};
  --border-radius-medium: #{$border-radius-medium};
  --border-radius-large: #{$border-radius-large};
  --border-radius-full: #{$border-radius-full};

  --page-header-height: #{$page-header-height};
  --page-header-height-2-line: #{$page-header-height-2-line};

  --page-hub-client-selector-height: #{$page-hub-client-selector-height};
  --page-hub-footer-height: #{$page-hub-footer-height};

  --page-body-min-height: #{$page-body-min-height};

  --page-bottom-navigation-height: 56px;

  --form-stepper-height: 72px;

  --page-sidebar-width: 200px;
  --page-sidebar-collapsed-width: 50px;

  --table-pagination-height: 52px;

  --site-header-z-index: 6;

  --filter-menu-height-desktop: 100px;

  --color-audit: --color-red;
  --color-accepted: --color-green;

  --timing-quartic: #{$timing-quartic};

  --bp-xxs: #{$bp-xxs};
  --bp-xs: #{$bp-xs};
  --bp-sm: #{$bp-sm};
  --bp-md: #{$bp-md};
  --bp-lg: #{$bp-lg};
  --bp-xl: #{$bp-xl};
  --bp-xxl: #{$bp-xxl};
}

:export {
  colorPurple: $color-purple;
  colorOrange: $color-orange;
  colorOrangeBright: $color-orange-bright;
  colorYellow: $color-yellow;
  colorYellowBright: $color-yellow-bright;
  colorYellowFade: $color-yellow-fade;
  colorTeal: $color-teal;
  colorTealLight: $color-teal-light;
  colorTealDark: $color-teal-dark;

  colorBlue: $color-blue;
  colorBlueDarker: $color-blue-darker;
  colorBlueDark: $color-blue-dark;
  colorBlueLight: $color-blue-light;
  colorBlueLighter: $color-blue-lighter;
  colorBlueLightest: $color-blue-lightest;
  colorBlueDarkFade: $color-blue-dark-fade;
  colorBlueLightFade: $color-blue-light-fade;
  colorBlueAlt: $color-blue-alt;
  colorBlueAlt2: $color-blue-alt2;

  /* Layout */
  colorPurpleDark: $color-purple-dark;
  colorPurpleLight: $color-purple-light;
  colorBlack: $color-black;
  colorWhite: $color-white;
  colorGrayDarkest: $color-gray-darkest;
  colorGrayDarker: $color-gray-darker;
  colorGrayDark: $color-gray-dark;
  colorGrayMedium: $color-gray-medium;
  colorGrayMiddle: $color-gray-middle;
  colorGray: $color-gray;
  colorGrayLight: $color-gray-light;
  colorGrayLighter: $color-gray-lighter;
  colorGrayLightest: $color-gray-lightest;
  colorGrayMediumAlt: $color-gray-medium-alt;

  colorGrayAlphaLowest: $color-gray-alpha-lowest;
  colorGrayAlphaLower: $color-gray-alpha-lower;
  colorGrayAlphaLow: $color-gray-alpha-low;
  colorGrayAlphaMid: $color-gray-alpha-mid;


  /* Other Colors */

  /* Status */
  colorGreen: $color-green;
  colorGreenAlt: $color-green-alt;
  colorRed: $color-red;
  colorYellow-bright: $color-yellow-bright;

  colorMUIRed: $color-mui-red;

  colorRG0: $color-rg-0;
  colorRG10: $color-rg-10;
  colorRG20: $color-rg-20;
  colorRG30: $color-rg-30;
  colorRG40: $color-rg-40;
  colorRG50: $color-rg-50;
  colorRG60: $color-rg-60;
  colorRG70: $color-rg-70;
  colorRG80: $color-rg-80;
  colorRG90: $color-rg-90;
  colorRG100: $color-rg-100;

  colorPrimary: $color-primary;
  colorPrimaryFaded: $color-primary-faded;
  colorPrimaryInverse: $color-primary-inverse;
  colorSecondary: $color-secondary;
  colorSecondaryFaded: $color-secondary-faded;
  colorSecondaryInverse: $color-secondary-inverse;
  colorTertiary: $color-tertiary;
  colorTertiaryFaded: $color-tertiary-faded;
  colorTertiaryInverse: $color-tertiary-inverse;

  gradientPurple: $gradient-purple;
  gradientBlack: $gradient-black;
  gradientGray: $gradient-gray;
  gradientGrayVert: $gradient-gray-vert;
  iconRadial: $icon-radial;

  dropShadow: $drop-shadow;
  dropShadowSharp: $drop-shadow-sharp;
  shadowInner: $shadow-inner;
  shadowInnerTop: $shadow-inner-top;

  timingQuartic: $timing-quartic;

  pageBodyMinHeight: $page-body-min-height;

  breakPointXXS: $bp-xxs;
  breakPointXS: $bp-xs;
  breakPointSM: $bp-sm;
  breakPointMD: $bp-md;
  breakPointLG: $bp-lg;
  breakPointXL: $bp-xl;
  breakPointXXL: $bp-xxl;
}
