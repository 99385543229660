@import "../../styles/base/variables";

.form {
  position: relative;

  &__section {
    padding: 30px 30px 30px 20px;
  }

  &-group {
    width: 100%;

    & > div {
      margin-right: 10px;
    }

    & > div:last-child {
      margin-right: 0;
    }
  }

  &-control {
    margin-bottom: 15px !important;
  }

  &__loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }

  &-stepper {
    // margin-bottom: 40px;
  }

  &-actions {
    display: flex;
    padding: 20px;

    & > button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    &__button {
      position: relative;
      width: 100%;
    }
  }

  .button--progress {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }

  &__helpertext {
    font-size: 0.75rem;
    color: $color-gray-darkest;
    margin-top: 20px;
  }
}

.select-notch {
  background: white;
  position: absolute;
  height: 5px;
  z-index: 1002;
  left: 10px;
  top: -1px;
}
