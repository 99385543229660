// src is relative to `main.scss`
@font-face {
  font-family: "Source Sans Pro";
  src: url("../../fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../../fonts/Source_Sans_Pro/SourceSansPro-Black.ttf");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../../fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../../fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../../fonts/Source_Sans_Pro/SourceSansPro-Light.ttf");
  font-weight: 300;
  font-style: normal;
}
