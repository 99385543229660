.hub-environment-title{
  margin-right: 1rem;
  flex: 1 0 auto;
}

.hub-environment-wrapper{
  display: flex;
  align-items: flex-end;
  padding: 0.5rem;
}

.hub-environments{
  display: flex;
  // padding: 0.25rem;
  // flex: 1 0 var(--page-header-height);
  align-items: center;
  // padding: 0px 20px;

  .hub-title{
    margin-right: 1rem;
    align-self: flex-end;
    line-height: 1em;
  }

  .environment{
    margin: 0 0.25rem;
    border-radius: 50%;
    border: 1px solid var(--color-gray-darker);
    cursor: pointer;

    &.current{
      border: 2px solid var(--color-green);
    }
  }
}
